import t from '@jetshop/intl';
import React from 'react';
import styled from 'react-emotion';
import { Link } from 'react-router-dom';
import { useQuery } from 'react-apollo';
import HomeCategories from './Layout/Header/HomeCategoriesQuery.gql';

import MaxWidth from './Layout/MaxWidth';
import { theme } from './Theme';
import Image from '@jetshop/ui/Image';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';

import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import CategoryLink from '@jetshop/ui/CategoryLink';

const ErrorContainer = styled(MaxWidth)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  padding: 1rem 3rem;
  padding-top: 3rem;
  ${theme.below.sm} {
    padding: 1rem;
  }
  * {
    font-family: ${theme.fontFamilies.body};
    color: #383838;
  }
`;

const ErrorContent = styled.div`
  font-size: 16px;
  line-height: 26px;
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding: 0.5rem;
  padding-bottom: 4em;
  .image {
    margin-right: 1rem;
    width: 50%;
    ${theme.above.md} {
      margin-right: 2rem;
      width: 40%;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    margin-top: -7px;
    p {
      margin-bottom: 12px;
    }
    .actions {
      display: flex;
      margin-top: auto;
      a {
        margin-right: 2rem;
        padding: 0.65rem 1.65rem;
        color: white;
        font-weight: bold;
        border-radius: 5px;
        min-width: 155px;
        text-align: center;
        &.primary {
          background: #662d91;
        }
        &.secondary {
          background: #f7931d;
        }
      }
    }
  }

  ${theme.below.sm} {
    flex-wrap: wrap;
    .image {
      width: 100%;
      margin-right: 0;
    }
    .actions {
      justify-content: space-between;
      margin-top: 2rem !important;
      a {
        margin-right: 0 !important;
      }
    }
  }

  h1,
  h2 {
    font-style: normal;
    line-height: 44px;
    font-size: 26px;
    ${theme.above.md} {
      font-size: 40px;
    }
  }
`;

const ErrorBody = styled('div')`
  font-family: Archivo;
  font-style: normal;
  font-size: 14px;
  line-height: 18px;
  color: #999999;
  width: 100%;
`;

const CategoryGrid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  margin-bottom: 4rem;
  width: 100%;
  li {
    width: 33.3333%;
    padding: 1rem 0.5rem;
    ${theme.below.md} {
      width: 50%;
    }
    h3 {
      font-weight: bold;
      margin-top: 0.5rem;
    }
  }
`;

function NotFoundPage() {
  const { notFoundPageCategoryId, newsCategoryId } = useShopConfig();
  const notFoundQuery = useQuery(CategoryPreviewQuery, {
    variables: {
      id: notFoundPageCategoryId
    }
  });
  const newsCategoryQuery = useQuery(CategoryPreviewQuery, {
    variables: {
      id: newsCategoryId
    }
  });

  const category =
    !notFoundQuery.loading &&
    !notFoundQuery.error &&
    notFoundQuery?.data?.category;

  return (
    <ErrorContainer>
      <ErrorContent>
        {category && (
          <>
            <div className="image">
              <Image src={category.images} sizes={[1]} aspect="1:1" cover />
            </div>
            <div className="content">
              <ErrorBody
                dangerouslySetInnerHTML={{ __html: category.content }}
              />
              <div className="actions">
                <Link className="primary" to="/">
                  {t('Homepage')}
                </Link>
                <Link
                  className="secondary"
                  to={newsCategoryQuery?.data?.category?.primaryRoute?.path}
                >
                  {newsCategoryQuery?.data?.category?.name ?? 'News'}
                </Link>
              </div>
            </div>
          </>
        )}
      </ErrorContent>
      <Grid />
    </ErrorContainer>
  );
}

const flatCategories = (categories, category) => {
  if (category.hasSubcategories && category.subcategories.length > 0) {
    categories = [...categories, ...category.subcategories];
  }
  categories = [...categories, category];
  return categories;
};

function Grid() {
  const { notFoundPageCategoryIds } = useShopConfig();

  const { error, loading, data } = useQuery(HomeCategories, {
    variables: {
      levels: 2
    }
  });

  if (loading || error) {
    return null;
  }

  // Flattens all categories, removes dupclicates and returns the one specified above
  const grid = [
    ...new Map(
      data?.categories
        ?.reduce(flatCategories, [])
        .map(category => [category.id, category])
    ).values()
  ].filter(category => notFoundPageCategoryIds.includes(category.id));

  return (
    <CategoryGrid>
      {grid.length > 0
        ? grid.map(c => {
            return <Item key={c.id} category={c} />;
          })
        : null}
    </CategoryGrid>
  );
}

function Item({ category }) {
  const { name, id, images } = category;
  if (!images.length) {
    console.log(`Category '${name}' with ID ${id} is missing it's image`);
  }
  return (
    <li>
      <CategoryLink category={category}>
        <Image
          src={images?.length ? images : '/notfound.jpg'}
          sizes={[1]}
          cover
          aspect="16:9"
        />
        <h3>{name}</h3>
      </CategoryLink>
    </li>
  );
}

export default NotFoundPage;
